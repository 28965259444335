<template>
  <div
    class="text-center"
    style="min-height: 100%; background-color: #ffffff;"
  >
    <div
      class="hero-bg"
      style="height: 300px"
    >
      <h1>SEARCH {{ search }}</h1>
    </div>

    <v-container
      fluid
      style="background-color: #f4f4f4;"
    >
      <v-row
        justify="center"
        style="min-height: 100vh;"
        class="py-12"
      >
        <v-col
          v-for="(post,i) in posts"
          :key="i"
          cols="auto"
        >
          <postcard 
            :post="post"
            :geo="geo"
            @click="postClick(i)"
          />
        </v-col>
      </v-row>
    </v-container>

    <div
      class="hero-bg"
      style="min-height: 300px"
    />
    <tcc-foot />
  </div>
</template>

<script>
import postcard from "../components/PostCard.vue";
import tccFoot from "@/components/tccFooter.vue";

export default {
    name: 'Search',
    components: {
        postcard,
        tccFoot
    },
    data() {
        return {
            posts: [],
            geo: null,
            customerSignedIn: false,
            customer: null,
            search: ""
        };
    },
    watch: {
        '$route.query'() {
            this.doSearch();
        }
    },
    async mounted() {
        //IF PEOPLE SHARE SEARCHES IT SHOULD SHOW WHAT THEY SEE AND IGNORE LOCATION??
        console.log( "SEARCH MOUNTED", window.location.pathname );
        // if( window.location.pathname.length > 1 )return;//not home path
        //if(!query.q)//redirect home?
 
        let res = await this.$http.getCustomer();
        console.log( "getCustomer", res );
        if( res.ok ){
            this.geo = res.data.geo;
            this.customer = res.data;
            this.$emit( 'user', res.data, res.token );
            this.customerSignedIn = true;

            //if( this.$route.query.tk ) this.$router.replace( '/' ).catch( ()=>{} );//clean up url
        }else{ //ANON CUSTOMER
            let reg =  await this.$http.getRegionObj();
            if( reg.latitude )this.geo = [ reg.longitude, reg.latitude ];
            console.log( "GEO", this.geo ); 
        }

        await this.doSearch();


        // this.promoArea();
        // console.log( "KM",km );
        // if( this.$route.query.lat && this.$route.query.long )this.geo = [ parseFloat( this.$route.query.long ), parseFloat( this.$route.query.lat ) ];
        // //WE need to also get any stores the customer follows
        // console.log( this.geo );
        // res = await this.$http.postsNearMe( this.geo, km );
        // if( res.ok )this.posts = res.data;

    },
    destroyed(){
        console.log( "SEARCH UNMOUNTED" );
    },
    methods: {
        async doSearch(){
            let query = this.$route.query;
            console.log( "SEARCH QUERY", query );
            document.title = "The Clearance Club - Search " + query.q;
            this.search = query.q;  
            if( !query.q )return;
            this.posts = [];
            let res = await this.$http.findPosts( this.geo, 0, this.search );
            if( res.ok )this.posts = res.data;

            //IF NOTHING FOUND THEN SHOW SUGGESTIONS ETC.
            // https://wordnet.cs.princeton.edu/downloads/1K.clean.txt
            //https://github.com/zaibacu/thesaurus/blob/master/en_thesaurus.jsonl
            // https://tuna.thesaurus.com/relatedWords/desk?limit=6
            // USE THIS TO DO SYNONYMS AND SUGGESTIONS

        }
    }


};
</script>

<style scoped>

.hero-bg {
  width: 100%;
  /* height: 35vw;  */
  /* min-height: 35vh; */
  background: url( '/img/tcc-background.png' ) repeat fixed top center;
  /* background-size: cover; */
}

</style>

